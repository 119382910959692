import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Bienvenue à Seventic
			</title>
			<meta name={"description"} content={"Réussir dans le domaine numérique - Votre vision, notre expertise\n"} />
			<meta property={"og:title"} content={"Bienvenue à Seventic"} />
			<meta property={"og:description"} content={"Réussir dans le domaine numérique - Votre vision, notre expertise\n"} />
			<meta property={"og:image"} content={"https://frenchriviera.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frenchriviera.live/media/551-5512749.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<meta name={"msapplication-TileImage"} content={"https://frenchriviera.live/media/551-5512749.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 220px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--primary"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Localisation et contact
				</Text>
				<Link
					href="mailto:blank?office@frenchriviera.live"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					office@frenchriviera.live
				</Link>
				<Link
					href="tel:+33 1 1525 3844"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+33 1 1525 3844
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
					240 Rue Championnet, 75018 Paris, France
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});